import { default as aboutDNh8c1HqhUMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/about.vue?macro=true";
import { default as _91blogSlug_93hAmbRZ5d0pMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/[blogSlug].vue?macro=true";
import { default as _91blogCategory_93y84TB489sSMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/category/[blogCategory].vue?macro=true";
import { default as indexjZWTv54I5aMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/index.vue?macro=true";
import { default as contactMnQRxARWTrMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/contact.vue?macro=true";
import { default as _91slug_93JPuPBcuvaEMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/green-card/[slug].vue?macro=true";
import { default as indexK67LDaZDnaMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/green-card/index.vue?macro=true";
import { default as indexX2bivZnlkCMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/index.vue?macro=true";
import { default as privacy_45policydnJIJrterZMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/privacy-policy.vue?macro=true";
import { default as search8hSZ2lLkv2Meta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/search.vue?macro=true";
import { default as terms_45of_45useLQV6W7qWgsMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/terms-of-use.vue?macro=true";
import { default as testimonialk6fBEGhzyLMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/testimonial.vue?macro=true";
import { default as videom6Hmkn9m6WMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/video.vue?macro=true";
import { default as _91slug_93ebvcSZAB3fMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/visas/[slug].vue?macro=true";
import { default as index6F3LPJaBitMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/visas/index.vue?macro=true";
import { default as webinartoClGctP5NMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/webinar.vue?macro=true";
import { default as component_45stubCEr6YxZOjFMeta } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubCEr6YxZOjF } from "/Users/zeze/Desktop/grapelaw/glf-website-v2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___tr",
    path: "/tr/hakkimizda",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "blog-blogSlug___en",
    path: "/blog/:blogSlug()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/[blogSlug].vue").then(m => m.default || m)
  },
  {
    name: "blog-blogSlug___tr",
    path: "/tr/blog/:blogSlug()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/[blogSlug].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-blogCategory___en",
    path: "/blog/category/:blogCategory()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/category/[blogCategory].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-blogCategory___tr",
    path: "/tr/blog/kategori/:blogCategory()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/category/[blogCategory].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___tr",
    path: "/tr/bize-ulasin",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "green-card-slug___en",
    path: "/green-card/:slug()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/green-card/[slug].vue").then(m => m.default || m)
  },
  {
    name: "green-card-slug___tr",
    path: "/tr/green-card/:slug()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/green-card/[slug].vue").then(m => m.default || m)
  },
  {
    name: "green-card___en",
    path: "/green-card",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/green-card/index.vue").then(m => m.default || m)
  },
  {
    name: "green-card___tr",
    path: "/tr/green-card",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/green-card/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___tr",
    path: "/tr/gizlilik-politikasi",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___tr",
    path: "/tr/ara",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___tr",
    path: "/tr/kullanim-kosullari",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "testimonial___en",
    path: "/testimonial",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/testimonial.vue").then(m => m.default || m)
  },
  {
    name: "testimonial___tr",
    path: "/tr/muvekkillerimizden",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/testimonial.vue").then(m => m.default || m)
  },
  {
    name: "video___en",
    path: "/video",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/video.vue").then(m => m.default || m)
  },
  {
    name: "video___tr",
    path: "/tr/video",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/video.vue").then(m => m.default || m)
  },
  {
    name: "visas-slug___en",
    path: "/visas/:slug()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/visas/[slug].vue").then(m => m.default || m)
  },
  {
    name: "visas-slug___tr",
    path: "/tr/vizeler/:slug()",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/visas/[slug].vue").then(m => m.default || m)
  },
  {
    name: "visas___en",
    path: "/visas",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/visas/index.vue").then(m => m.default || m)
  },
  {
    name: "visas___tr",
    path: "/tr/vizeler",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/visas/index.vue").then(m => m.default || m)
  },
  {
    name: "webinar___en",
    path: "/webinar",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/webinar.vue").then(m => m.default || m)
  },
  {
    name: "webinar___tr",
    path: "/tr/webinar",
    component: () => import("/Users/zeze/Desktop/grapelaw/glf-website-v2/pages/webinar.vue").then(m => m.default || m)
  },
  {
    name: component_45stubCEr6YxZOjFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubCEr6YxZOjF
  }
]