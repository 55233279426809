
// @ts-nocheck
import locale__Users_zeze_Desktop_grapelaw_glf_website_v2_locales_en_json from "../locales/en.json";
import locale__Users_zeze_Desktop_grapelaw_glf_website_v2_locales_tr_json from "../locales/tr.json";


export const localeCodes =  [
  "en",
  "tr"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale__Users_zeze_Desktop_grapelaw_glf_website_v2_locales_en_json), cache: true }],
  "tr": [{ key: "../locales/tr.json", load: () => Promise.resolve(locale__Users_zeze_Desktop_grapelaw_glf_website_v2_locales_tr_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "iso": "tr",
      "files": [
        "locales/tr.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "en": "/",
      "tr": "/"
    },
    "about": {
      "en": "/about",
      "tr": "/hakkimizda"
    },
    "visas/index": {
      "en": "/visas",
      "tr": "/vizeler"
    },
    "visas/[slug]": {
      "en": "/visas/[slug]",
      "tr": "/vizeler/[slug]"
    },
    "green-card/index": {
      "en": "/green-card",
      "tr": "/green-card"
    },
    "green-card/[slug]": {
      "en": "/green-card/[slug]",
      "tr": "/green-card/[slug]"
    },
    "blog/index": {
      "en": "/blog",
      "tr": "/blog"
    },
    "blog/[blogSlug]": {
      "en": "/blog/[blogSlug]",
      "tr": "/blog/[blogSlug]"
    },
    "blog/category/[blogCategory]": {
      "en": "/blog/category/[blogCategory]",
      "tr": "/blog/kategori/[blogCategory]"
    },
    "success-story/index": {
      "en": "/success-story",
      "tr": "/basari-hikayesi"
    },
    "success-story/[slug]": {
      "en": "/success-story/[slug]",
      "tr": "/basari-hikayesi/[slug]"
    },
    "testimonial": {
      "en": "/testimonial",
      "tr": "/muvekkillerimizden"
    },
    "e-book/index": {
      "en": "/e-book",
      "tr": "/e-kitap"
    },
    "e-book/[slug]": {
      "en": "/e-book/[slug]",
      "tr": "/e-kitap/[slug]"
    },
    "video": {
      "en": "/video",
      "tr": "/video"
    },
    "webinar": {
      "en": "/webinar",
      "tr": "/webinar"
    },
    "contact": {
      "en": "/contact",
      "tr": "/bize-ulasin"
    },
    "search": {
      "en": "/search",
      "tr": "/ara"
    },
    "privacy-policy": {
      "en": "/privacy-policy",
      "tr": "/gizlilik-politikasi"
    },
    "terms-of-use": {
      "en": "/terms-of-use",
      "tr": "/kullanim-kosullari"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "iso": "en",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Türkçe",
    "iso": "tr",
    "files": [
      {
        "path": "locales/tr.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
